body,
html {
    background-color: #000;
}
.full-page,
body,
html {
    overflow-x: hidden;
}
#white_popup {
    width: 80vw;
    height: 80vh;
    background-color: #000;
    position: fixed;
    top: 9vh;
    left: 13vw;
    z-index: 100;
    box-shadow: 0 0 75px 0 #fff;
}
#white_popup img {
    width: 100%;
    display: block;
}
.white_inserts {
    width: 100vw;
    background-color: rgba(48, 48, 48, 0.9);
    position: relative;
    box-shadow: 0 0 75px 0 #fff;
}
.white_inserts,
.white_inserts_images {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-direction: column;
    flex-direction: column;
}
.white_inserts_images .horizontal {
    width: 90vw;
    margin-bottom: 1.5vw;
    box-shadow: 0 0 75px 0 #fff;
}
.white_inserts_images .vertical {
    height: 90vw;
    margin-bottom: 1.5vw;
    box-shadow: 0 0 75px 0 #fff;
}
.white_inserts_desc {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-direction: column;
    flex-direction: column;
}
.white_inserts_desc div:first-child {
    width: 90vw;
}
.white_inserts_desc div:last-child {
    width: 97vw;
}
.white_inserts_desc div:last-child p {
    text-shadow: 1px 2px 20px #fff;
    font-family: serif;
    font-size: 2.5vw;
    color: #fff;
}
.white_inserts_desc div:first-child p {
    text-shadow: 1px 2px 20px #fff;
    font-family: sans-serif;
    font-size: 2.4vw;
    margin-bottom: 0;
    color: #fff;
}
.white_inserts_desc div:first-child p:last-child {
    margin: 0;
}
.white_inserts_desc p a {
    color: #fff;
    border-bottom: 1px solid;
    font-family: sans-serif;
}
.Navbar_email {
    background-color: transparent;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 100vw;
    height: 50vh;
    border-radius: 50%;
    border: 1px dotted #fff;
}
.Navbar_email p {
    font-size: 9.3vw;
    font-family: serif;
    font-weight: lighter;
    color: #000;
    text-align: center;
    text-shadow: 1px 2px 20px #fff;
    opacity: 0.7;
    width: 100vw;
}
.NavBarHeader {
    width: 100%;
    position: fixed;
    top: 0;
    z-index: -1;
}
.FixedImg img {
    position: fixed;
    height: 100vh;
    width: 100vw;
}
.Fixed_Barbed {
    z-index: -1;
}
.firstImage img {
    position: absolute;
    right: 0;
    width: 70vw;
    top: 0;
    z-index: 1000;
}
.FixedComponentsA {
    opacity: 0.9;
    z-index: 3;
    overflow-y: scroll;
    width: 95vw;
    height: auto;
    position: absolute;
    top: 4vw;
    -ms-transform: rotate(2deg);
    transform: rotate(2deg);
    overflow: hidden;
    opacity: 1;
    width: 85vw;
}
.FixedComponentsA p {
    width: 72vw;
    font-weight: lighter;
    margin: -1%;
    color: #000;
    font-family: serif;
    text-align: center;
    padding: 3vw;
    font-size: 1vw;
    border-radius: 70%;
    font-size: 1.8vw;
    -ms-transform: scaleY(0.8);
    transform: scaleY(0.8);
    padding-right: 0;
    line-height: 80&;
    padding-left: 5vw;
    animation: scale_firstBackgroundWhite 10s infinite;
    text-shadow: -3px 0 30px #fff;
}
.FixedComponentsA span {
    color: #000;
}
.FixedComponentsA:hover,
.FixedComponentsA p:hover {
    animation-play-state: paused;
}
.FixedComponentsA_2 {
    width: 20vw;
    height: 25vw;
    -ms-transform: rotate(2deg);
    transform: rotate(2deg);
    opacity: 1;
    top: 24vw;
    left: 71vw;
    z-index: 1000;
}
.FixedComponents2,
.FixedComponentsA_2 {
    background-color: #fff;
    box-shadow: 6px 10px 17px 0 #fff;
    position: absolute;
}
.FixedComponents2 {
    border-radius: 60%;
    opacity: 0.9;
    overflow: hidden;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    top: 11vw;
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
    width: 40vw;
    height: 20vh;
    opacity: 1;
    animation: animationFrames3 20s infinite;
    z-index: 1;
    right: 5vw;
    top: 33vw;
}
.insert_one_span {
    background-color: #fff;
    animation: scale_about 10s infinite;
}
.FixedComponents2 img {
    height: 49%;
    padding-left: 18px;
    animation: rotation 10s infinite linear;
}
.FixedComponents2:hover {
    animation-play-state: paused;
}
.FixedComponents2 p {
    margin: 0;
    color: #000;
    text-align: center;
    font-size: 1.3vw;
    font-family: arial narrow;
    position: relative;
    top: -1.5vw;
    -ms-transform: scaleY(0.8);
    transform: scaleY(0.8);
    animation: scale_firstBackground 10s infinite;
}
.firstBackground {
    height: 47vh;
    width: 100vw;
}
.firstBackground img {
    width: 50vw;
    position: absolute;
    z-index: 100;
    top: 62.7vw;
    right: 0;
    -ms-transform: rotate(-1deg);
    transform: rotate(-1deg);
    animation: firstBackgroundX 5s linear 0s infinite alternate, firstBackgroundY 5s linear 0s infinite alternate;
}
.MarqueeFixed1 {
    background-color: #fff;
    position: fixed;
    top: 0;
    right: 0;
    height: auto;
    width: 12vw;
    z-index: 10000;
    box-shadow: 1px 2px 20px #fff;
}
.first_intro,
.MarqueeFixed1 {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
}
.first_intro {
    position: absolute;
    color: #000;
    z-index: 10;
    font-size: 1.4vw;
    text-align: left;
    font-weight: lighter;
    text-shadow: 1px 2px 20px #fff;
    color: #fff;
    background-color: transparent;
    border-radius: 20px;
    width: 50vw;
    top: 3vw;
    padding: 1vw;
    font-family: serif;
}
.firstBackground_intro {
    top: 30vw;
    width: 52vw;
    left: 2vw;
    position: absolute;
    z-index: 10;
}
.firstBackground_intro p {
    top: 0;
    font-size: 5.4vw;
    text-align: left;
    font-family: serif;
    animation: scale_firstBackground 10s infinite;
    padding: 0;
    margin: 0;
    text-shadow: 1px 2px 20px #fff;
    z-index: -10;
    line-height: 100%;
    font-weight: lighter;
}
.MarqueeFixed1 p {
    color: blue;
    font-family: serif;
    margin: 0;
    white-space: nowrap;
}
.Fixed_infos {
  position: fixed;
  display: flex;
  z-index: 1000;
  align-items: center;
  justify-content: center;
  color: #fff;
  margin-top: 0.3vw;
  bottom: 1vw;
  left: 1vw;
  background-color: #fff;
  flex-direction: column;
  z-index: 1000000;
  font-family: serif;
  padding: 0.5vw;
  margin-bottom: 0.4vw;
  line-height: 170%;
}
.Fixed_infos p {
    font-size: 1.4vw;
    margin: 0;
    padding: 0;
    color: blue;
    cursor: pointer;
}
.Fixed_infos a:after {
    content: " -  ";
}
.Fixed_infos a:last-child:after {
    content: "";
}
.title_projects {
    color: #fff;
    display: -ms-flexbox;
    display: flex;
}
.vertical_projects {
    height: 50vw;
    width: auto !important;
}
.slick-list,
.slick-slider,
.slick-track {
    z-index: 10;
}
.slider_image {
    background-color: gray;
}
.h1_container h1 {
    top: 0;
    font-size: 4vw;
    text-align: left;
    font-family: serif;
    color: #000;
    padding: 0;
    margin: 0;
    text-shadow: 1px 2px 20px #fff;
    line-height: 100%;
    font-weight: lighter;
    z-index: 100;
    width: 100%;
}
.h1_container span {
    font-family: Arial Black, Arial Bold, Gadget, sans-serif;
}
.h1_container div:first-child {
    width: 90%;
}
.h1_container div:first-child h1 {
    text-align: center;
    font-family: Times New Roman, Times, serif;
}
.h1_container div:first-child p {
    position: absolute;
}
.h1_container div:nth-child(2) {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
}
.h1_container div:nth-child(2) h1 {
    color: #000;
    border-radius: 20px;
    padding: 0.3vw;
    text-shadow: 1px 2px 20px #fff;
    font-size: 3vw;
    padding: 1vw;
    font-family: Arial Black, Arial Bold, Gadget, sans-serif;
}
.NavBar_slider {
    z-index: 100;
    position: absolute;
}
.slick-dots {
    position: absolute;
    top: -1vw;
    width: 0;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-direction: column;
    flex-direction: column;
    z-index: 1000;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    height: 100vh;
    left: 2vw;
}
.slick-dots li div {
    font-size: 2vw;
    text-shadow: 1px 2px 20px #000;
    width: 220%;
    border: 1px solid #fff;
    background-color: #fff;
    border-radius: 20px;
    font-family: Arial Black, Arial Bold, Gadget, sans-serif;
    box-shadow: 6px 10px 17px 0 grey;
}
.desc_container {
    width: 100vw;
    height: 100vh;
    position: absolute;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    visibility: hidden;
}
.desc_container p {
    background-color: #000;
    color: #fff;
    padding-top: 3vw;
    padding: 8vw;
    padding-bottom: 3vw;
    margin: 0;
    width: 60vw;
    font-size: 1.7vw;
    box-shadow: 6px 10px 17px #000;
    line-height: 110%;
    font-family: serif;
    border-radius: 70%;
}
.desc_container span {
    display: -ms-flexbox;
    display: flex;
    margin-top: 2vw;
    font-family: Arial Black, Arial Bold, Gadget, sans-serif;
}
.slick-dots li {
    height: auto;
    margin-bottom: 1vw;
}
.slick-active div {
    font-family: serif !important;
    text-shadow: 1px 2px 20px #fff;
}
.central_projects {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    width: 95vw;
    height: 100vh;
}
.title_container div:first-child {
    margin-bottom: 1vw;
}
.title_container div span {
    font-family: serif;
}
.title_container p {
    font-size: 2.4vw;
    text-align: left;
    color: #000;
    font-family: serif;
    padding: 0;
    margin: 0;
    text-shadow: 1px 2px 20px #fff;
}
.title_container img {
    width: 100%;
}
.verticalMarquee1 marquee {
    position: absolute;
    left: 0;
    top: 10vw;
    width: 22vw;
    background-color: #fff;
    overflow: hidden;
    -ms-transform-origin: left bottom;
    transform-origin: left bottom;
    white-space: nowrap;
    font-weight: lighter;
    -ms-transform: rotate(-90deg) translateY(100%) translateX(-90%);
    transform: rotate(-90deg) translateY(100%) translateX(-90%);
}
.projects_test {
    background-color: transparent;
    height: 100vh;
    position: fixed;
    width: 100vw;
    z-index: 1000;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    display: none;
}
.projects_test_inner {
    background-color: #000;
    height: 96vh;
    width: 95vw;
    overflow: scroll;
}
.test_projects {
    margin: 2vw;
}
.test_projects img {
    width: 100%;
}
.verticalMarquee1 marquee p {
    margin: 0;
    text-shadow: 0 0 70px #404040;
    font-size: 6vw;
    display: none;
}
.info_mobile {
    display: none;
    width: 100vw;
    height: auto;
    top: 0;
    position: fixed;
    text-shadow: 1px 2px 20px #000;
}
.info_mobile,
.info_mobile a {
    font-size: 20vw;
    -webkit-text-stroke: 0.3px #fff;
    color: transparent;
}
.info_mobile a {
    margin: 0;
    text-align: center;
    display: block;
    -ms-transform: scaleY(0.8);
    transform: scaleY(0.8);
    font-family: serif;
}
.secondBackground_forth_img {
    position: absolute;
    bottom: 6vw;
    right: 0;
    width: 50vw;
    z-index: -1;
    animation: skewAnim 4s infinite;
}
.secondBackground_fifth_img {
    position: relative;
    width: 29vw;
    top: -19vw;
    left: 7vw;
    padding-top: -27vw;
    animation: skewAnim 10s infinite;
    display: none;
}
.secondBackground_sixth_img {
    position: absolute;
    width: 32vw;
    -ms-transform: rotate(-60deg);
    transform: rotate(-60deg);
    z-index: -1;
    top: 103vw;
    left: 5vw;
    display: none;
}
.secondBackground {
    width: 100vw;
    position: relative;
    height: auto;
}
.SecondBackground_insertTwo {
    position: absolute;
    top: 150vw;
}
.secondBackground_second_img {
    width: 70vw;
    animation: flash2 20s infinite;
}
.secondBackground_third_img {
    width: 25vw;
}
.secondBackground marquee {
    -ms-transform: rotate(5deg);
    transform: rotate(5deg);
    position: absolute;
    display: inline-block;
    overflow: hidden;
    text-align: initial;
    white-space: nowrap;
    background-color: transparent;
    width: 26vw;
    -ms-transform: rotate(40deg);
    transform: rotate(-5deg);
    right: 13vw;
    top: 43vw;
    border: 1px solid;
    display: none;
}
.secondBackground marquee p {
    margin: 0;
}
#sticky_1 {
    width: 30vw;
    position: fixed;
    visibility: hidden;
    z-index: 10000000;
}
.typeWritter {
    font-size: 6.5vw;
    color: #fff;
    position: absolute;
    width: 100%;
    text-align: center;
    z-index: 100;
}
.img_sticky_1 {
    height: 17vw;
    animation: animationFrames3 20s infinite;
    position: relative;
    top: -13vw;
    z-index: 1;
}
.sticky-inner-wrapper {
    z-index: 10000;
}
.About_page_container .Fixed_Water img {
    display: block;
    position: fixed;
    top: 0;
    z-index: 1000;
}
iframe {
    width: 100vw;
}
.vimeo_embed {
    width: 90vw;
}
.main_project_container {
    margin-bottom: 8vw;
    margin-top: 7vw;
}
.Fixed_Water img {
    display: block;
    position: fixed;
    top: 0;
    z-index: 1;
}
.About_page_container {
    position: absolute;
}
.About_page {
    background-color: #000;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 102vw;
    position: relative;
    top: 0;
    -ms-flex-direction: column;
    flex-direction: column;
    line-height: 9vw;
    z-index: 100;
}
.About_page p {
    margin-top: 3vw;
    -ms-transform: rotate(-3deg);
}
.About_page_2 p,
.About_page p {
    background-color: #000;
    font-size: 7.3vw;
    left: 3vw;
    top: -1vw;
    text-shadow: 1px 2px 20px #fff;
    font-family: serif;
    transform: rotate(-1deg);
}
.About_page_2 p {
    -webkit-transform: rotate(2deg);
    -ms-transform: rotate(2deg);
    width: 98vw;
    line-height: 93%;
    border: 1px dotted #fff;
    text-align: center;
    margin: 0;
    z-index: 10000;
    position: relative;
    font-weight: 700;
    margin-top: 3vw;
    -ms-transform: rotate(-3deg);
    padding-top: 3vw;
    padding-bottom: 3vw;
    background-color: #fff;
    -webkit-text-stroke: 0.3px #000;
    color: #fff;
}
.about_fixed_bottom {
    padding-top: 2vw;
    background-color: #000;
    bottom: 0;
    position: fixed;
    z-index: 1;
    border-top: 1px solid #fff;
    width: 100vw;
    height: 20vh;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
}
.About_page p {
    font-size: 7vw;
    -webkit-transform: rotate(2deg);
    -ms-transform: rotate(2deg);
    background-color: #fff;
    width: 98vw;
    left: -3vw;
    -webkit-text-stroke: 0.3px #000;
    color: #fff;
    line-height: 93%;
    border: 1px dotted #fff;
    text-align: center;
    margin: 0;
    z-index: 10000;
    position: relative;
    top: 0;
    font-weight: 700;
    font-family: arial;
    padding-top: 3vw;
    padding-bottom: 3vw;
    box-shadow: 1px 2px 20px #fff;
    display: inline-block;
    letter-spacing: -1px;
}
.slider_small {
    height: 100vh;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
}
.headlines_projects {
    color: #fff;
    font-size: 6vw;
    margin: 0;
    font-family: serif;
    font-weight: lighter;
    text-shadow: 1px 2px 20px #fff;
    text-transform: uppercase;
}
.project_container {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-pack: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
}
.slick-slide {
    display: -ms-flexbox !important;
    display: flex !important;
}
.title_h1 {
    font-family: Arial Narrow, Arial, sans-serif;
    font-weight: lighter;
    text-align: left;
    position: fixed;
    left: 30vw;
    bottom: 0;
    z-index: 10000;
    width: 50vw;
}
.title_h1,
.title_span {
    font-size: 3vw;
    color: #000;
    margin: 0;
    text-shadow: 1px 2px 20px #fff;
}
.title_span {
    font-family: Arial Black, Arial Bold, Gadget, sans-serif;
}
.title_span:before {
    content: " ";
}
.desc_span {
    position: fixed;
    top: 0;
    width: auto;
    left: 0;
    width: 60vw;
    font-size: 1.6vw;
    font-family: arial black;
    margin: 0;
    text-shadow: 1px 2px 20px #fff;
    z-index: 100000;
    text-align: left;
}
.desc_span,
.external_text {
    padding-left: 2vw;
    padding-right: 2vw;
    color: #000;
}
.title_desc {
    font-size: 1.6vw;
    color: #fff;
    font-family: Arial Black, Arial Bold, Gadget, sans-serif;
    margin: 0;
    text-align: center;
    font-family: serif;
}
.details_span:before {
    content: "  ";
}
.details_span {
    color: #fff;
    font-family: TimesNewRoman;
    font-weight: 700;
}
.pulse_about {
    background-color: #000;
}
.pulse_about,
.pulse_index {
    animation: pulse_about 10s infinite;
    -ms-transform: scaleY(0.5);
    transform: scaleY(0.5);
}
.pulse_index {
    background-color: #fff;
}
.scale_about {
    animation: scale_about 10s infinite;
}
.scale_about,
.scale_slider {
    -ms-transform: scaleY(0.1);
    transform: scaleY(0.1);
}
.scale_slider {
    animation: scale_slider 10s infinite;
}
.scale_index {
    -ms-transform: scaleY(0.1);
    transform: scaleY(0.1);
    animation: scale_index 10s infinite;
}
.scale_about_black {
    animation: scale_about_black 10s infinite;
}
.rotate_about {
    animation: rotate_about 20s infinite;
}
.blink_about,
.rotate_about {
    -ms-transform: rotate(4deg);
    transform: rotate(4deg);
}
.blink_about {
    animation: blink_about 20s infinite;
}
.About_page_p_two {
    margin-top: 3vw;
    left: -1vw;
    -ms-transform: rotate(4deg);
    transform: rotate(4deg);
}
.About_page .photo-content {
    opacity: 0.5;
}
.back_index_About {
    position: fixed;
    left: 0;
    height: auto;
    z-index: 10000;
    z-index: 100000000;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    bottom: 0;
    color: #fff;
}
.About_page_container .MarqueeFixed2 {
    bottom: 0;
    background-color: blue;
    position: fixed;
    right: 0;
    left: inherit;
    border-left: 1px dotted blue;
    border-bottom: 1px dotted blue;
    display: none;
}
.About_page_container .MarqueeFixed2 a {
    color: #fff;
}
.back_index_About p,
.back_index_About p a {
    margin: 0;
    font-size: 2vw;
}
.back_index_About p a {
    color: #000;
    padding: 0;
    font-family: sans-serif;
    white-space: nowrap;
}
.loading_screen {
    background-color: #000;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
}
.loading_screen h1 {
    top: 0;
    font-size: 5.4vw;
    text-align: left;
    font-family: serif;
    animation: scale_firstBackground 10s infinite;
    padding: 0;
    margin: 0;
    text-shadow: 1px 2px 20px #fff;
    z-index: -10;
    line-height: 100%;
    font-weight: lighter;
}
.verticalMarquee2 marquee {
    position: absolute;
    top: 10vw;
    width: 52vw;
    background-color: transparent;
    overflow: hidden;
    white-space: nowrap;
    font-weight: lighter;
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
    -ms-transform-origin: top right;
    transform-origin: top right;
}
.verticalMarquee2 marquee p {
    margin: 0;
    text-shadow: 0 0 70px #404040;
    font-size: 6vw;
}
a {
    text-decoration: none;
    text-shadow: 1px 2px 20px #fff;
    cursor: pointer;
}
a,
link:visited {
    color: #000;
}
.thirdBackground_first_img {
    position: relative;
    width: 10vw;
}
.fifthBackground_forth_img {
    position: relative;
    z-index: 10;
    width: 9vw;
    -ms-transform: rotate(-4deg);
    transform: rotate(-4deg);
    top: 1vw;
    left: 10vw;
    animation: animationFrames3 20s infinite;
}
.fifthBackground .flex_container {
    padding-top: 3vw;
    top: 0;
}
.fifthBackground_fifth_img {
    position: absolute;
    z-index: 10;
    left: -10vw;
    width: 80vw;
    top: 10vw;
}
.info_container {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
}
.info {
    background-color: #fff;
    width: 80vw;
    height: 76vh;
}
.fifthBackground {
    width: 100vw;
    height: auto;
    position: relative;
}
.marquee_div_right {
    width: 39vw;
    height: 100vh;
    position: absolute;
    right: 0;
}
.bio_rotate {
    -ms-transform: rotate(2deg);
    transform: rotate(2deg);
}
.first_project {
    width: 60vw;
}
.second_project {
    width: 80vw;
}
.flex_container {
    display: -ms-flexbox;
    display: flex;
    top: -23vw;
    width: 100vw;
    position: relative;
    margin: 0 auto;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
}
.flex_container_projects .inline_block {
    display: inline-block;
    margin-left: 3vw;
    margin-bottom: 5vw;
}
.LoadingContainer {
    background-color: red;
    width: 100vw;
    height: 100vh;
}
.spinner {
    margin: 100px auto 0;
    width: 70px;
    text-align: center;
}
.spinner > div {
    width: 18px;
    height: 18px;
    background-color: #333;
    border-radius: 100%;
    display: inline-block;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}
.spinner .bounce1 {
    animation-delay: -0.32s;
}
.spinner .bounce2 {
    animation-delay: -0.16s;
}
.fifthBackground_first_img {
    z-index: 100;
    width: 30vw;
    padding-bottom: 34vw;
}
.fifthBackground_second_img {
    width: 59vw;
    z-index: 100;
    animation: flash2 20s infinite;
}
.fifthBackground_seventh_img {
    width: 69vw;
    position: absolute;
    bottom: -63vw;
    -ms-transform: rotate(2deg);
    transform: rotate(2deg);
    animation: skewAnim 6s infinite;
}
.secondBackground .secondBackground_first_img {
    display: none;
    width: 50vw;
    z-index: 10;
    position: absolute;
    top: 80vw;
    animation: firstBackgroundX 5s linear 0s infinite alternate, firstBackgroundY 5s linear 0s infinite alternate;
}
.SeventhBackground {
    width: 100vw;
    height: auto;
    position: relative;
}
.NinthBackground {
    background-color: #000;
    height: auto;
    position: relative;
}
.NinthBackground,
.NinthBackground_secondFold {
    width: 100vw;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
}
.NinthBackground_secondFold {
    margin-bottom: 2vw;
}
.NinthBackground_absolute {
    position: absolute;
    z-index: 100;
}
.NinthBackground_secondFold div:last-child img {
    width: 70vw;
    animation: flash2 20s infinite;
}
.NinthBackground_secondFold div:first-child {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
}
.NinthBackground_secondFold div:first-child img {
    width: 30vw;
    box-shadow: 0 0 75px 0 #fff;
    margin-left: 2vw;
    margin-right: 2vw;
    margin-bottom: 0;
}
.NinthBackground_secondFold div:first-child img:last-child {
    animation: flash 0.1s infinite;
}
.NinthBackground img {
    width: 87vw;
    margin: 3vw;
    box-shadow: 0 0 75px 0 #fff;
    animation: skewAnim 6s infinite;
}
.thirdBackground {
    width: 100vw;
    height: auto;
    position: relative;
}
.thirdBackground_forth_img {
    width: 60vw;
    top: -13vw;
    animation: animationFrames1 4s infinite;
    position: absolute;
    z-index: 10;
}
.thirdBackground_insert .insert_two {
    position: absolute;
    top: 53vw;
}
.thirdBackground_fifth_img {
    width: 10vw;
    animation: animationFrames2 10s infinite;
    position: absolute;
    z-index: 1;
}
.thirdBackground_sixth_img {
    width: 50vw;
    position: absolute;
    right: 40vw;
    top: 20vw;
    z-index: 10;
}
.thirdBackground .flex_container {
    -ms-flex-pack: center;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 0 auto;
}
.thirdBackground_third_img {
    width: 72vw;
    z-index: 1;
    animation: flash2 20s infinite;
}
.thirdBackground_second_img {
    width: 25vw;
    -ms-transform: rotate(2deg);
    transform: rotate(2deg);
    z-index: 10;
}
.thirdBackground_secondFold {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
}
.thirdBackground_secondFold img {
    width: 60vw;
    margin-top: -23vw;
}
.thirdBackground_secondFold img:first-child {
    box-shadow: 0 0 75px 0 #fff;
    animation: flash2 20s infinite;
}
.thirdBackground_secondFold img:last-child {
    animation: flash2 0.1s infinite;
}
.fourthBackground .thirdBackground_second_img {
    animation: skewAnim 4s infinite;
    margin-top: -43vw;
    margin-top: -13vw;
    width: 32vw;
}
.SkewAnim {
    animation: skewAnim 2s infinite;
    animation-delay: 10s;
}
.display_flex {
    position: absolute;
    width: 100vw;
    margin-top: 164vw;
    z-index: 10000;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
}
.display_flex img {
    -ms-transform: rotate(1deg);
    transform: rotate(1deg);
}
.fourthBackground {
    height: auto;
    width: 100vw;
    position: relative;
}
.fourthBackground_secondFold {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
}
.fourthBackground_absolute {
    position: absolute;
    margin-top: 24vw;
    left: 0;
}
.fourthBackground_secondFold img:first-child {
    width: 35vw;
    animation: flash2 0.1s infinite;
    box-shadow: 0 0 75px 0 #fff;
}
.fourthBackground_secondFold img:last-child {
    width: 50vw;
    -ms-transform: rotate(2deg);
    transform: rotate(2deg);
    animation: skewAnim 6s infinite;
}
.fourthBackground_h1 {
    position: absolute;
    width: 100vw;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    background-color: #000;
}
.fourthBackground_h1 h1 {
    width: 93vw;
    font-size: 4.5vw;
    z-index: 100;
    text-shadow: 1px 2px 20px #fff;
    text-align: center;
    animation: secondBackground_p 10s infinite;
}
.fourthBackground_quotes1 {
    top: 40px;
    -ms-transform: rotate(7deg);
    transform: rotate(7deg);
    width: 96vw;
    position: absolute;
    background-color: transparent;
    left: 70px;
    z-index: 1000000;
}
.grey_background_first {
    width: 80vw;
    height: 100vh;
    position: absolute;
    background-color: #000;
    z-index: 10;
    display: none;
}
.fourthBackground_seventh_img,
.fourthBackground_sixth_img {
    width: 34vw;
    margin-bottom: 1vw;
    margin-top: 3vw;
    -ms-transform: rotate(-2deg);
    transform: rotate(-2deg);
    box-shadow: 0 0 75px 0 #fff;
}
.fourthBackground_seventh_img {
    animation: skewAnim 5s infinite;
}
.fourthBackground_eight_img {
    width: 11vw;
    box-shadow: none;
    position: absolute;
    top: 12vw;
    opacity: 0.9;
    z-index: 100;
    animation: animationFrames3 20s infinite;
}
.fourthBackground_ninth_img {
    width: 16vw;
    position: absolute;
}
.fourthBackground_tenth_img {
    position: absolute;
    left: 360px;
}
.fourthBackground_fourtenth_img,
.fourthBackground_tenth_img {
    width: 62vw;
    bottom: 15vw;
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
    z-index: -1;
    animation: flash 0.1s infinite;
}
.fourthBackground_fourtenth_img {
    left: 500px;
}
.fourthBackground_eleventh_img {
    display: none;
    width: 13vw;
    left: 32vw;
    position: absolute;
    top: 1vw;
    animation: animationFrames3 20s infinite;
}
.fourthBackground_fourtenth_img {
    width: 4vw;
    left: 32vw;
    position: absolute;
    top: 1vw;
    animation: animationFrames3 40s infinite;
}
.fourthBackground_twelve_img {
    width: 27vw;
    position: absolute;
    left: 36vw;
    top: 10vw;
    box-shadow: 0 0 75px 0 #fff;
    opacity: 0.9;
    animation: flash 0.1s infinite;
}
.fourthBackground_thirtinth_img {
    width: 9vw;
    position: absolute;
    top: 14vw;
    animation: rotation 30s infinite linear;
}
.thirdBackground_fixed1 {
    animation: FixedComponents2 10s infinite;
    background-color: #fff;
    width: 45vw;
    margin-left: 43vw;
    height: 70vh;
    position: relative;
    -ms-transform: rotate(2deg);
    transform: rotate(2deg);
    box-shadow: 6px 10px 17px 0 #fff;
    right: 3vw;
    opacity: 0.9;
    border-radius: 50%;
    display: none;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
}
.thirdBackground_fixed1 p {
    width: 80%;
    text-align: center;
    font-weight: lighter;
}
.thirdBackground_fixed2 {
    background-color: #fff;
    width: 6vw;
    height: 10vh;
    position: absolute;
    transform: rotate(2deg);
    box-shadow: 6px 10px 17px 0 #fff;
    left: 20vw;
    opacity: 0.9;
    border-radius: 50%;
}
.insert_one,
.thirdBackground_fixed2 {
    -ms-transform: rotate(2deg);
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
}
.insert_one {
    background-color: #000;
    width: 98vw;
    border: 1px dotted #fff;
    margin-top: -10vw;
    height: 50vh;
    transform: rotate(4deg);
    padding-left: 1vw;
}
.insert_one h1 {
    font-size: 7vw;
    padding-left: 2vw;
    text-shadow: 1px 2px 20px #fff;
    animation: bounceInLeft 10s infinite;
}
.insert_one img {
    opacity: 1;
    width: 15vw;
    right: 0;
    position: absolute;
    top: 0;
    animation: skewAnim 5s infinite;
}
.insert_four {
    background-color: #000;
    width: 98vw;
    border: 1px dotted #fff;
    margin-top: -10vw;
    height: 50vh;
    -ms-transform: rotate(2deg);
    transform: rotate(4deg);
    padding-left: 1vw;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
}
.insert_four h1 {
    font-size: 7vw;
    padding-left: 2vw;
    text-shadow: 1px 2px 20px #fff;
    animation: bounceInLeft 10s infinite;
}
.insert_four img {
    opacity: 1;
    width: 15vw;
    right: 0;
    position: absolute;
    top: 0;
    animation: skewAnim 5s infinite;
}
.insert_five {
    background-color: #000;
    width: 98vw;
    border: 1px dotted #fff;
    margin-top: -10vw;
    height: 50vh;
    -ms-transform: rotate(2deg);
    transform: rotate(4deg);
    padding-left: 1vw;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    top: 0;
    position: absolute;
}
.insert_five h1 {
    font-size: 7vw;
    padding-left: 2vw;
    text-shadow: 1px 2px 20px #fff;
    animation: bounceInLeft 10s infinite;
}
.insert_five img {
    opacity: 1;
    width: 15vw;
    right: 0;
    position: absolute;
    top: 0;
    animation: skewAnim 5s infinite;
}
.insert_two {
    background-color: #000;
    width: 98vw;
    border: 1px dotted #fff;
    height: 50vh;
    -ms-transform: rotate(2deg);
    transform: rotate(4deg);
    padding-left: 1vw;
}
.insert_two h1 {
    font-size: 7vw;
    padding-left: 2vw;
    text-shadow: 1px 2px 20px #fff;
    animation: bounceInLeft 10s infinite;
}
.insert_eight {
    background-color: #000;
    width: 98vw;
    border: 1px dotted #fff;
    height: 40vh;
    -ms-transform: rotate(2deg);
    transform: rotate(4deg);
    padding-left: 1vw;
}
.insert_eight h1 {
    font-size: 7vw;
    padding-left: 2vw;
    text-shadow: 1px 2px 20px #fff;
    animation: bounceInLeft 10s infinite;
}
.insert_seventh {
    background-color: #000;
    width: 98vw;
    border: 1px dotted #fff;
    height: 50vh;
    -ms-transform: rotate(2deg);
    transform: rotate(4deg);
    margin-top: -12vw;
    padding-left: 1vw;
}
.insert_seventh h1 {
    font-size: 7vw;
    line-height: 90%;
    padding-left: 2vw;
    text-shadow: 1px 2px 20px #fff;
    -webkit-animation: bounceInLeft 10s infinite;
}
.insert_ninth {
    background-color: #000;
    width: 98vw;
    border: 1px dotted #fff;
    height: 50vh;
    -ms-transform: rotate(2deg);
    transform: rotate(4deg);
    padding-left: 1vw;
    display: none;
}
.insert_ninth h1 {
    font-size: 7vw;
    line-height: 90%;
    padding-left: 2vw;
    text-shadow: 1px 2px 20px #fff;
    -webkit-animation: bounceInLeft 10s infinite;
}
.insert_sixth {
    background-color: #000;
    width: 98vw;
    border: 1px dotted #fff;
    height: 50vh;
    -ms-transform: rotate(2deg);
    transform: rotate(4deg);
    padding-bottom: 4vw;
    padding-left: 1vw;
}
.insert_sixth h1 {
    font-size: 7vw;
    padding-left: 2vw;
    text-shadow: 1px 2px 20px #fff;
    line-height: 97%;
    animation: bounceInLeft 10s infinite;
}
.insert_three {
    background-color: #000;
    width: 98vw;
    border: 1px dotted #fff;
    height: 50vh;
    -ms-transform: rotate(2deg);
    transform: rotate(4deg);
    padding-left: 1vw;
    bottom: 0;
    position: absolute;
    z-index: 1;
}
.insert_three h1 {
    font-size: 7vw;
    padding-left: 2vw;
    text-shadow: 1px 2px 20px #fff;
}
.TriangleBackground p {
    color: #fff;
    font-size: 4vw;
    padding: 2vw;
    margin: 0;
    overflow: hidden;
    text-shadow: 1px 2px 20px #fff;
}
.sticky_test {
    width: 20vw;
}
.TriangleBackground img {
    display: none;
    animation: TriangleBackgroundX 20s linear 0s infinite alternate, Y 20s linear 0s infinite alternate;
    width: 12vw;
    position: relative;
    left: 12vw;
}
.fourthBackground_fixed1 {
    animation: FixedComponents2 10s infinite;
    background-color: #fff;
    position: relative;
    width: 53vw;
    height: 10vh;
    -ms-transform: rotate(2deg);
    transform: rotate(2deg);
    box-shadow: 6px 10px 17px 0 #fff;
    opacity: 0.9;
    border-radius: 50%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: absolute;
    margin-top: 0;
    margin-left: 0;
    left: 30vw;
}
.marquee_test {
    margin-top: 77vw;
    position: absolute;
}
.marquee_test h1 {
    font-size: 6vw;
    text-shadow: 0 0 70px #404040;
    -moz-animation: secondBackground_p 10s infinite;
    -webkit-animation: secondBackground_p 10s infinite;
}
.fifthBackground marquee {
    position: absolute;
    display: inline-block;
    width: 25vw;
    z-index: 100;
    top: -32vvw;
    margin-top: -32vw;
    left: 12vw;
    display: none;
}
.fifthBackground marquee,
.thirdBackground marquee {
    -ms-transform: rotate(5deg);
    transform: rotate(5deg);
    overflow: hidden;
    text-align: initial;
    white-space: nowrap;
    -ms-transform: rotate(40deg);
    transform: rotate(40deg);
}
.thirdBackground marquee {
    position: relative;
    display: none;
    opacity: 0.9;
    z-index: 1000;
    width: 23vw;
}
.fourthBackground .insert_two {
    top: -11vw;
    position: absolute;
}
.fourthBackground_fifth_img {
    width: 80vw;
    box-shadow: 0 0 75px 0 #fff;
    margin-top: 2vw;
    margin-bottom: 2vw;
    margin-left: 2vw;
    animation: flash2 20s infinite;
}
.thirdBackground marquee p {
    font-size: 6vw;
    text-align: center;
    text-shadow: 0 0 70px #404040;
    -moz-animation: secondBackground_p 10s infinite;
    -webkit-animation: secondBackground_p 10s infinite;
    -webkit-text-stroke: 0.3px #fff;
    text-shadow: 1px 2px 20px #fff;
}
.text_stroke {
    -webkit-text-stroke: 0.3px #000;
    text-shadow: 1px 2px 20px #888;
    display: inline-block;
}
.fourthBackground p {
    top: 0;
    font-size: 5.4vw;
    width: 101%;
    text-align: center;
    font-family: serif;
    animation: scale_firstBackground 10s infinite;
    padding: 0;
    margin: 0;
    text-shadow: 1px 2px 20px #fff;
    z-index: -10;
    line-height: 100%;
    font-weight: lighter;
    text-align: left;
}
.fourthBackground .flex_container {
    top: 0;
}
.fourthBackground_first_img {
    animation: animationFrames1 4s infinite;
    width: 30vw;
}
.fourthBackground_second_img {
    animation: rotation 10s infinite linear;
    width: 15vw;
    left: 22vw;
    position: absolute;
    top: 33vw;
    z-index: 10;
}
.fourthBackground_third_img {
    width: 13vw;
    margin-left: 12vw;
    position: absolute;
    top: 22vw;
    z-index: 100000000000;
    animation: rotation 4s infinite linear;
}
.crÃ©dits {
    position: absolute;
    background-color: #fff;
    right: 1.7vw;
    padding: 1vw;
    z-index: 1000000000;
    margin-top: -4.22vw;
}
.crÃ©dits a {
    font-size: 1.4vw;
    margin: 0;
    padding: 0;
    color: #000;
    cursor: pointer;
    font-family: serif;
    text-transform: uppercase;
}
.crÃ©dits p {
    color: blue;
}
.crÃ©dits span {
    border-bottom: 1px solid #000;
}
.crÃ©dits_ipad {
    visibility: hidden;
    background-color: #fff;
    z-index: 10000;
    width: 100vw;
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    box-shadow: 1px 2px 20px #fff;
}
.crÃ©dits_ipad p {
    font-size: 3vw;
}
.crÃ©dits_ipad p,
.crÃ©dits_ipad span {
    text-shadow: 1px 2px 20px #888;
    -ms-transform: scaleY(0.5);
    transform: scaleY(0.5);
}
.crÃ©dits_ipad span {
    border-bottom: 1px solid #000;
}
.BottomInfoBackground {
    border: 0;
    background-color: #fff;
    bottom: 0;
    z-index: 100;
    height: 3vh;
    display: -ms-flexbox;
    display: flex;
    display: none;
}
.bottom_div,
.BottomInfoBackground {
    position: relative;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
}
.bottom_div {
    background-color: #000;
    display: -ms-flexbox;
    display: flex;
    width: 100vw;
    height: 50vh;
    border-radius: 50%;
    border: 1px dotted #fff;
}
.bottom_div_second {
    -ms-transform: rotate(-8deg);
    transform: rotate(-8deg);
}
.fourthBackground_fourth_img {
    display: none;
    width: 78vw;
    position: absolute;
    -ms-transform: rotate(2deg);
    transform: rotate(2deg);
    top: 38vw;
}
.SixthBackground img {
    width: 13vw;
    position: absolute;
}
.SeventhBackground_first_img img {
    width: 72vw;
    position: relative;
    -ms-transform: rotate(4deg);
    transform: rotate(4deg);
    box-shadow: 0 0 75px 0 #fff;
}
.SeventhBackground_second_img {
    position: relative;
}
.SeventhBackground_eight_img {
    width: 33vw;
    z-index: 10000000;
    position: absolute;
    right: 0;
    animation: animationFrames3 20s infinite;
}
.SeventhBackground_second_img img {
    width: 20vw;
    right: 0;
    position: absolute;
    z-index: 0;
    animation: skewAnim 0.2s infinite;
    left: 0;
    display: none;
}
.SeventhBackground_third_img {
    right: 0;
    position: absolute;
}
.SeventhBackground_third_img img {
    width: 16vw;
    right: 20vw;
    top: -72vw;
    z-index: 1000;
    position: absolute;
    animation: animationFrames3 20s infinite;
}
.SeventhBackground_fourth_img {
    width: 12vw;
    z-index: 10;
    top: 23vw;
    left: 13vw;
    position: relative;
    animation: animationFrames3 20s infinite;
    animation: rotation 10s infinite linear;
}
.SeventhBackground_ninth_img {
    width: 20vw;
    z-index: 1000000;
    opacity: 0.8;
    right: 0;
    z-index: 100;
    position: absolute;
    animation: animationFrames3 20s infinite;
    box-shadow: 0 0 75px 0 #000;
}
.EightBackground {
    width: 100vw;
    position: relative;
}
.EightBackground_fifth_img {
    width: 50vw;
    position: relative;
    padding-top: -22vw;
    top: -8vw;
    animation: flash2 20s infinite;
}
.EightBackground_sixth_img {
    position: absolute;
}
.EightBackground_big_img_container_two {
    width: 75vw;
    background-color: #000;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    border: 1px dotted #fff;
    padding: 4vw;
    margin-top: 7vw;
    position: absolute;
    left: -5vw;
    z-index: 10000000000;
    -ms-transform: rotate(4deg);
    transform: rotate(4deg);
    display: none;
}
.EightBackground_first_img {
    width: 55vw;
    position: relative;
    top: -6vw;
    left: 1vw;
    box-shadow: 0 0 75px 0 #fff;
    z-index: 1000;
}
.EightBackground_second_img {
    width: 75vw;
    height: 110vh;
    position: absolute;
    top: 77vw;
    left: 25vw;
    z-index: 100;
    display: none;
}
.EightBackground_third_img {
    top: -13vw;
    left: 17vw;
    z-index: 1000;
    width: 100vw;
    position: absolute;
}
.EightBackground_fourth_img {
    right: 0;
    z-index: 100;
    width: 50vw;
    position: absolute;
    top: 18vw;
    animation: flash2 20s infinite;
}
.EightBackground .grey_background_second {
    -ms-transform: rotate(-2deg);
    transform: rotate(-2deg);
    left: -3vw;
    z-index: 10;
}
.EightBackground .insert_four {
    position: relative;
}
.grey_background_second {
    position: absolute;
    width: 38vw;
    height: 39vh;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    background-color: #000;
}
.SeventhBackground_flex_first {
    position: relative;
    top: -10vw;
    z-index: 10;
    width: 58vw;
}
.flex_container_small {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-direction: column;
    flex-direction: column;
}
.SeventhBackground_flex_second {
    width: 50vw;
    z-index: 100;
    padding-top: 3vw;
    animation: flash2 20s infinite;
}
.SeventhBackground .flex_container {
    top: -8vw;
}
.SeventhBackground_seventh_img {
    width: 54vw;
    position: absolute;
    top: 33vw;
    left: 0;
    z-index: 1;
    animation: skewAnim 6s infinite;
}
.SeventhBackground_sixth_img {
    opacity: 0.8;
    width: 13vw;
    top: 0;
    -webkit-transform: rotate(2deg);
    -ms-transform: rotate(2deg);
    animation: rotation 10s infinite linear;
}
.SeventhBackground_fifth_img {
    opacity: 0.8;
    width: 32vw;
    position: absolute;
    top: 0;
    display: none;
    z-index: -1;
}
.grey_background_third {
    right: 0;
    position: absolute;
    width: 38vw;
    height: 39vh;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    background-color: #000;
}
.grey_background_third img {
    width: 16vw;
    top: 2vw;
    z-index: 10;
    opacity: 0.9;
    animation: flash 0.1s infinite;
}
.grey_background_second img {
    width: 87%;
    opacity: 1;
}
.EightBackground_big_img_container {
    z-index: 1000;
    background-color: transparent;
    width: 83vw;
    right: 0;
    display: -ms-flexbox;
    position: absolute;
    display: flex;
    -ms-flex-align: center;
    padding: 0;
    align-items: center;
    margin: 0;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-transform: rotate(2deg);
    transform: rotate(2deg);
}
.EightBackground_big_img_container img {
    width: 90%;
    -ms-flex-align: center;
    align-items: center;
    margin: 3vw;
    box-shadow: 0 0 75px 0 #fff;
}
.image_project {
    width: 100vw;
}
.fifthBackground p {
    color: #000;
    font-size: 6vw;
    text-align: center;
    text-shadow: 0 0 70px #404040;
    -moz-animation: secondBackground_p 10s infinite;
    -webkit-animation: secondBackground_p 10s infinite;
    color: hsla(0, 0%, 100%, 0);
    -webkit-text-stroke: 1px #fff;
}
.fifthBackground_sixth_img {
    position: absolute;
    bottom: 2vw;
    width: 9vw;
    animation: rotation 4s infinite linear;
    left: 13vw;
    top: 13vw;
    z-index: 100;
}
.secondBackground .TriangleBackground {
    display: none;
    top: 63vw;
    position: relative;
    bottom: 0;
    z-index: 1;
    animation: firstBackgroundX 5s linear 0s infinite alternate, firstBackgroundY 5s linear 0s infinite alternate;
}
.secondBackground p {
    color: #000;
    display: none;
    position: absolute;
    font-size: 7vw;
    text-align: center;
    position: relative;
    text-shadow: 0 0 70px #404040;
    -moz-animation: secondBackground_p 10s infinite;
    -webkit-animation: secondBackground_p 10s infinite;
}
.p {
    position: absolute;
    left: 0;
    height: 100vw;
    width: 10vw;
    background-color: #000;
}
.marquee {
    white-space: nowrap;
}
.photo-content img {
    width: 100vw;
    margin: 0 auto;
}
.bigImgs {
    width: 100vw;
}
.p_images img {
    position: absolute;
    width: 19vw;
    right: 0;
}
.marquee img {
    height: 10vh;
    padding-right: 2vw;
}
.flash {
    animation: flash 0.1s infinite;
}
@keyframes sk-bouncedelay {
    0%,
    80%,
    to {
        transform: scale(0);
    }
    40% {
        transform: scale(1);
    }
}
@keyframes flash {
    0%,
    50%,
    to {
        opacity: 1;
    }
    25%,
    75% {
        opacity: 0;
    }
}
@keyframes flash2 {
    0%,
    50%,
    to {
        opacity: 1;
    }
    25%,
    75% {
        opacity: 0.5;
    }
}
@keyframes skewAnim {
    0%,
    11.1%,
    to {
        transform: none;
    }
    22.2% {
        transform: skewX(-12.5deg) skewY(-12.5deg);
    }
    33.3% {
        transform: skewX(6.25deg) skewY(6.25deg);
    }
    44.4% {
        transform: skewX(-3.125deg) skewY(-3.125deg);
    }
    55.5% {
        transform: skewX(1.5625deg) skewY(1.5625deg);
    }
    66.6% {
        transform: skewX(-0.78125deg) skewY(-0.78125deg);
    }
    77.7% {
        transform: skewX(0.390625deg) skewY(0.390625deg);
    }
    88.8% {
        transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
    }
}
@keyframes rotation {
    0% {
        transform: rotate(0deg) translate(-150px) rotate(0deg);
    }
    to {
        transform: rotate(1turn) translate(-150px) rotate(-1turn);
    }
}
@keyframes rotationInverse {
    0% {
        transform: rotate(0deg) translate(150px) rotate(0deg);
    }
    to {
        transform: rotate(-1turn) translate(150px) rotate(1turn);
    }
}
@keyframes rotation_wide {
    0% {
        transform: rotate(0deg) translate(-100vw) rotate(0deg);
    }
    to {
        transform: rotate(1turn) translate(-150px) rotate(-1turn);
    }
}
@keyframes animationFrames2 {
    0% {
        transform: translate(0);
    }
    45% {
        transform: translate(10px, -11px);
    }
    83% {
        transform: translate(50px, 100px);
    }
    to {
        transform: translate(0);
    }
}
@keyframes animationFrames3 {
    0% {
        transform: translate(0);
    }
    45% {
        transform: translate(10px, -11px);
    }
    83% {
        transform: translate(50px, 100px);
    }
    to {
        transform: translate(0);
    }
}
@keyframes FixedComponents2 {
    0% {
        left: 10vw;
    }
    to {
        left: 13vw;
    }
}
@keyframes marquee {
    0% {
        left: 0;
    }
    to {
        left: -100%;
    }
}
@keyframes animationFrames1 {
    0% {
        transform: translate(0);
    }
    45% {
        transform: translate(44px, -11px);
    }
    83% {
        transform: translate(92px, -26px);
    }
    to {
        transform: translate(92px, -26px);
    }
}
@keyframes bounce {
    to {
        top: -30px;
        text-shadow: 1px 2px 20px #fff, 1px 2px 50px #fff, 1px 2px 60px #fff, 1px 2px 90px #fff, 1px 2px 90px #fff;
    }
}
@keyframes spinner {
    0% {
        transform: rotateY(0deg);
    }
    to {
        transform: rotateY(-1turn);
    }
}
@keyframes bounceInLeft {
    0%,
    60%,
    75%,
    90%,
    to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    0% {
        opacity: 1;
        transform: translate3d(-10px, 0, 0);
    }
    60% {
        opacity: 1;
        transform: translate3d(5px, 0, 0);
    }
    75% {
        opacity: 1;
        transform: translate3d(-10px, 0, 0);
    }
    90% {
        opacity: 1;
        transform: translate3d(5px, 0, 0);
    }
    to {
        opacity: 1;
        transform: none;
    }
}
@keyframes FixedComponentsA {
    0% {
        left: 90vw;
    }
    to {
        left: 20vw;
    }
}
@keyframes firstBackgroundX {
    0% {
        right: 0;
    }
    to {
        right: 3vw;
    }
}
@keyframes firstBackgroundY {
    0% {
        top: 92.7vw;
    }
    to {
        top: 115vw;
    }
}
@keyframes pulse_about {
    0% {
        transform: scaleX(1);
        background-color: #000;
    }
    50% {
        transform: scale3d(0.9, 0.3, 1.01);
        background-color: #000;
        opacity: 0;
    }
    to {
        transform: scaleX(1);
        background-color: #000;
    }
}
@keyframes rotate_about {
    0% {
        transform: rotate(4deg);
        transform: scale(0.7);
    }
    50% {
        transform: rotate(4deg);
        background-color: #000;
        transform: scale(0.5);
    }
    to {
        transform: rotate(4deg);
        transform: scale(0.7);
    }
}
@keyframes blink_about {
    0% {
        background-color: #000;
    }
    50% {
        background-color: #fff;
    }
    to {
        background-color: #000;
    }
}
@keyframes scale_about {
    0% {
        transform: scaleY(0.5);
        -webkit-text-stroke: 0;
    }
    50% {
        transform: scaleY(0.1);
        color: #000;
    }
    to {
        transform: scaleY(0.5);
        -webkit-text-stroke: 0;
    }
}
@keyframes scale_firstBackground {
    0% {
        transform: scale(1);
        -webkit-text-stroke: 0;
    }
    50% {
        transform: scaleY(0.8);
        color: #000;
    }
    to {
        transform: scale(1);
        -webkit-text-stroke: 0;
    }
}
@keyframes scale_firstBackgroundWhite {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scaleY(0.8);
    }
    to {
        transform: scale(1);
    }
}
@keyframes scale_slider {
    0% {
        transform: scaleY(0.7);
        -webkit-text-stroke: 0;
    }
    50% {
        transform: scale(1);
        color: #000;
    }
    to {
        transform: scaleY(0.7);
        -webkit-text-stroke: 0;
    }
}
@keyframes scale_info {
    0% {
        transform: scaleY(0.1);
        opacity: 1;
    }
    50% {
        transform: scaleY(0.1);
        opacity: 0.3;
    }
    to {
        transform: scaleY(0.1);
        opacity: 1;
    }
}
@keyframes scale_about_black {
    0% {
        transform: scaleY(0.5);
        -webkit-text-stroke: 0;
        background-color: #000;
    }
    50% {
        transform: scaleY(0.1);
        color: #000;
        background-color: #000;
    }
    to {
        transform: scaleY(0.5);
        -webkit-text-stroke: 0;
        background-color: #000;
    }
}
@media screen and (min-width: 0px) and (max-width: 500px) {
    body {
        overflow-x: hidden;
        width: 100%;
    }
    .NinthBackground_absolute {
        width: 50vw;
    }
    .loading_screen h1 {
        font-size: 13vw;
        text-align: center;
    }
    .external_text {
        display: block;
        width: 80%;
        left: 0;
        padding: 0;
    }
    .desc_span {
        display: none;
    }
    .title_h1 {
        width: 90vw;
        left: 5%;
    }
    .title_span {
        font-size: 6vw;
    }
    .first_intro {
        font-size: 6.5vw;
        z-index: 100;
        display: none;
    }
    .vertical_projects {
        width: 70vw !important;
        height: auto;
    }
    .insert_three {
        height: auto;
    }
    .full-page {
        overflow-x: hidden;
    }
    .firstBackground_intro {
        display: none;
    }
    .crÃ©dits_ipad {
        visibility: hidden;
    }
    .Fixed_infos,
    .FixedComponents2,
    .FixedComponentsA {
        display: none;
    }
    .About_page_2 p,
    .About_page p {
        font-size: 11vw;
    }
    .TriangleBackground {
        display: none;
    }
    .info_mobile {
        display: block;
        z-index: 100000;
        line-height: 100%;
        margin-bottom: 10vw;
    }
    .crÃ©dits,
    .firstBackground img {
        display: none;
    }
    .EightBackground .insert_four,
    .insert_eight,
    .insert_five,
    .insert_four,
    .insert_ninth,
    .insert_one,
    .insert_seventh,
    .insert_sixth,
    .insert_two {
        height: auto;
    }
    .back_index_About {
        width: 100vw;
        z-index: 100000;
    }
    .grey_background_first,
    .MarqueeFixed1,
    .MarqueeFixed2,
    .Navbar_email {
        display: none;
    }
    .title_h1 {
        font-size: 8vw;
    }
    .first_project {
        width: 92vw;
    }
    .title_desc {
        font-size: 4vw;
    }
    iframe {
        height: auto;
    }
    .image_project,
    iframe {
        width: 100vw !important;
    }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    body {
        overflow-x: hidden;
    }
    .Fixed_infos {
        width: 60%;
        margin-bottom: 1vw;
    }
    .loading_screen h1 {
        font-size: 8vw;
    }
    .first_intro {
        width: 80vw;
        font-size: 2.4vw;
    }
    #player,
    .fallback,
    iframe {
        background-color: #202020;
    }
    .FixedComponents2 {
        width: 52vw;
    }
    .full-page {
        overflow-x: hidden;
    }
    .firstBackground {
        display: none;
    }
    .EightBackground .insert_four,
    .insert_eight,
    .insert_five,
    .insert_four,
    .insert_ninth,
    .insert_one,
    .insert_seventh,
    .insert_sixth,
    .insert_two {
        height: auto;
    }
    .grey_background_first {
        display: none;
    }
    .back_index_About,
    .MarqueeFixed1,
    .MarqueeFixed2 {
        width: 31vw;
        z-index: 100000;
    }
    .FixedComponents2 img {
        height: 39%;
    }
    .About_page,
    .About_page_2 p {
        z-index: 100;
    }
    .crÃ©dits,
    .Navbar_email {
        display: none;
    }
    .crÃ©dits_ipad {
        display: hidden;
    }
    .FixedComponents2 {
        height: 12vh;
    }
    .Fixed_infos p {
        font-size: 3vw;
    }
    .thirdBackground_fifth_img {
        display: none;
    }
}
body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
}
